<template>
  <div>
    <v-alert>
      <h3>Data Pengaduan Yang Sudah Diselesaikan</h3>
    </v-alert>

    <div class="mb-3">
      <v-btn color="success" depressed @click="kembali()">
        <v-icon left dark>
          mdi-arrow-left
        </v-icon>
        Kembali
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="pengaduans"
      item-key="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <CModalView />
      </template>
      <template v-slot:[`item.nomor`]="{ item }">
        {{
          pengaduans
            .map(function(x) {
              return x.pengaduan_id;
            })
            .indexOf(item.pengaduan_id) + 1
        }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-1" @click="viewItem(item)">
          mdi-archive
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import CModalView from "@/components/Admin/pengaduan/pengaduan/modalView";

import modalView from "@/store/pengaduan/modalView";
// import modalHapus from "@/store/pengaduan/modalHapus";
import refreshView from "@/store/pengaduan/viewPengaduan";

export default {
  components: {
    CModalView,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    session: "",

    viewIndex: "",
    editedIndex: "",
    dleteIndex: "",

    pengaduans: [],
    pengaduan: {},

    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Judul Pengaduan", align: "start", value: "pengaduan_judul" },
      { text: "Jenis Pengaduan", align: "start", value: "jp_nama" },
      { text: "Action", value: "action", width: "100px" },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "pengaduan/selesai", {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.pengaduans = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    viewItem(item) {
      this.viewIndex = this.pengaduans.indexOf(item);
      this.pengaduan = Object.assign({}, item);
      modalView.commit("toggleModal", true);
      modalView.commit("viewModal", Object.assign({}, item));
    },

    kembali() {
      this.$router.push("/admin/pengaduan/data").catch(() => {});
    },
  },
};
</script>
